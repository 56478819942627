@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

body {
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Nunito", sans-serif;
}

html {
  scroll-behavior: smooth;
}

.card-background {
  background-color: #f3f1f1e7;
}
/* Title animation */
 .animation {
   font-size: 24px;
   font-weight: bold;
   position: relative;
   overflow: hidden;
   white-space: nowrap;
 }

 .animation::after {
   content: "";
   position: absolute;
   top: 0;
   left: -100%;
   width: 100%;
   height: 100%;
   background: linear-gradient(to right, transparent, #003C97);
   animation: slideRight 5s linear infinite;
 }

 @keyframes slideRight {
   0% {
     left: -100%;
   }

   100% {
     left: 100%;
   }
 }
/* Title animation end*/
@media screen and (max-width: 767px) {
  .banner-image {
    height: 500px;
  }

  .youth-title {
    max-width: 70%;
    margin: 0 auto;
  }
}
.scroll-to-top-icon {
  background-color: #2c6eb5;
  color: #ffffff;
  border-radius: 50%;
  padding: 15px;
  transition: background-color 0.3s, color 0.3s;
}

.scroll-to-top-icon:hover {
  background-color: #2a89ee;
  color: white;
}