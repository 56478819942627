.test {
    background-color: #003C97 !important;
    color: white !important;
}

@media screen and (max-width:767px) {
    .tabs:first-child {
        margin-left: 140px !important;
    }
}

 .animation {
     font-size: 24px;
     font-weight: bold;
     position: relative;
     overflow: hidden;
     white-space: nowrap;
 }

 .animation::after {
     content: "";
     position: absolute;
     top: 0;
     left: -100%;
     width: 100%;
     height: 100%;
     background: linear-gradient(to right, transparent, #003C97);
     animation: slideRight 5s linear infinite;
 }

   @keyframes slideRight {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }