/* css start */
.image-card-container {
  @apply rounded-xl;
  /* @apply pt-3 md:py-6; */
  /* @apply px-4; */
  @apply md:py-0;
  /* @apply md:p-4; */
  @apply relative;
  @apply w-full;
}

.image-card-container .image-card-gradient {
  @apply absolute;
  @apply top-0;
  @apply left-0;
  @apply w-full;
  @apply h-full;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 64.89%,
    rgba(0, 0, 0, 0) 64.89%,
    #003C97 100%
  );
  @apply rounded-lg;
}
/* css end */

/* gallery video slider css start */
.slick-slider {
    @apply w-full md:w-2/4;
  width: 100%;
}
.slick-track,
.slick-track .slick-slide {
  /* @apply flex h-auto !important; */
  @apply flex h-auto;
}
.slick-track .slick-slide > div {
  @apply flex;
}
.slick-slide > div > div {
  @apply flex !important;
  @apply w-full;
}
/* gallery slider css end */
/* slick dots start */
.slick-slider .slick-dots li button:before {
  width: 12px;
  height: 12px;
  content: "";
  border-radius: 50%;
  @apply opacity-100;
  background-color: #cdd5f7;
}
.slick-dots li.slick-active button:before {
  background: #14337e;
  @apply opacity-100;
}
.slick-dots{
  bottom: -40px;
} 
/* slick dots end */
@media screen and (max-width:767px) {
  .recent-update-content-card-size{
    height: 420px;
  }
}