:root {
    /* dark shades of primary color*/
    --clr-primary-1: hsl(205, 86%, 17%);
    --clr-primary-2: hsl(205, 77%, 27%);
    --clr-primary-3: hsl(205, 72%, 37%);
    --clr-primary-4: hsl(205, 63%, 48%);
    /* primary/main color */
    --clr-primary-5: hsl(205, 78%, 60%);
    /* lighter shades of primary color */
    --clr-primary-6: hsl(205, 89%, 70%);
    --clr-primary-7: hsl(205, 90%, 76%);
    --clr-primary-8: var(--clr-grey-5);
    --clr-primary-9: hsl(205, 90%, 88%);
    --clr-primary-10: hsl(205, 100%, 96%);
    /* darkest grey - used for headings */
    --clr-grey-1: hsl(209, 61%, 16%);
    --clr-grey-2: hsl(211, 39%, 23%);
    --clr-grey-3: hsl(209, 34%, 30%);
    --clr-grey-4: hsl(209, 28%, 39%);
    /* grey used for paragraphs */
    --clr-grey-5: #003C97;
    --clr-grey-6: hsl(209, 23%, 60%);
    --clr-grey-7: hsl(211, 27%, 70%);
    --clr-grey-8: hsl(210, 31%, 80%);
    --clr-grey-9: hsl(212, 33%, 89%);
    --clr-grey-10: hsl(210, 36%, 96%);
    --clr-white: #fff;
    --clr-red-dark: hsl(360, 67%, 44%);
    --clr-red-light: hsl(360, 71%, 66%);
    --clr-green-dark: hsl(125, 67%, 44%);
    --clr-green-light: hsl(125, 71%, 66%);
    --clr-black: #222;
    --ff-primary: "Roboto", sans-serif;
    --ff-secondary: "Open Sans", sans-serif;
    --transition: all 0.4s linear;
    --spacing: 0.1rem;
    --radius: 0.25rem;
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    --max-width: 1170px;
    --fixed-width: 620px;
  }

/*
=============== 
Sidebar
===============
*/
.sidebar-toggle {
    position: fixed;
    top: 4%;
    left: 1rem;
    font-size: 3rem;
    background: transparent;
    border-color: transparent;
    color: var(--clr-primary-5);
    transition: var(--transition);
    cursor: pointer;
    animation: bounce 2s ease-in-out infinite;
    z-index: 1;
  }
  .sidebar-toggle:hover {
    color: var(--clr-primary-7);
  }
  /* @keyframes bounce {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  } */
  
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
  }
  .close-btn {
    font-size: 1.75rem;
    background: transparent;
    border-color: transparent;
    color: var(--clr-primary-5);
    transition: var(--transition);
    cursor: pointer;
    color: var(--clr-red-dark);
    z-index: 1;
  }
  .close-btn:hover {
    color: var(--clr-red-light);
    transform: rotate(360deg);
  }
  .logo {
    justify-self: center;
    height: 40px;
  }
  
  .links a {
    display: block;
    font-size: 1.5rem;
    text-transform: capitalize;
    /* padding: 1rem 1.5rem; */
    color: var(--clr-grey-5);
    transition: var(--transition);
    border-bottom: 1px solid #3a6ce0;
  }
  .links a:hover {
    background: var(--clr-primary-8);
    color: rgb(245, 245, 245);
    padding-left: 1.75rem;
    border-radius: 5px;
    padding: 0px 35px;
  }
  .social-icons {
    justify-self: center;
    display: flex;
    padding-bottom: 2rem;
  }
  .social-icons a {
    font-size: 1.5rem;
    margin: 0 0.5rem;
    color: var(--clr-primary-5);
    transition: var(--transition);
  }
  .social-icons a:hover {
    color: var(--clr-primary-1);
  }
  
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 75%;
    height: 100%;
    background: white;
    display: grid;
    grid-template-rows: auto 1fr auto;
    row-gap: 1rem;
    box-shadow: var(--clr-red-dark);
    transition: var(--transition);
    transform: translate(-100%);
    z-index: 12;
  }
  .show-sidebar {
    transform: translate(0);
  }
  @media screen and (min-width: 676px) {
    .sidebar {
      width: 400px;
    }
  }
  @media screen and (max-width: 767px) {
    .links a {
      display: block;
      font-size: 1.2rem;
      text-transform: capitalize;
      /* padding: 1rem 1.5rem; */
      color: var(--clr-grey-5);
      transition: var(--transition);
      border-bottom: 2px solid transparent;
      border-image: linear-gradient(0.25turn, var(--clr-grey-5), rgba(56,2,155,0));
      border-image-slice: 1;
    }
  }
  .jjfoAQ{
    margin: 0rem !important;
  }
  .TimelineItemWrapper-sc-1dd6qhe-1.cXAOiP{
    height: 100px;
  }
  .eCfjpi.horizontal{
    min-height: 100px !important;
  }