/* steps video slider css start */
@media screen and (max-width: 767px) {
  
  .steps-slider-parent .slick-slider {
      @apply w-full;
  }
  .steps-slider-parent .slick-track,
  .steps-slider-parent .slick-track .slick-slide {
    /* @apply flex h-auto !important; */
    @apply flex h-auto;
  }
  .steps-slider-parent .slick-track .slick-slide > div {
    @apply flex;
  }
  .steps-slider-parent .slick-slide > div > div {
    @apply flex !important;
    @apply w-full;
  }
}

/* steps slider css end */
/* slick dots start */
.steps-slider-parent .slick-slider .slick-dots li button:before {
  width: 12px;
  height: 12px;
  content: "";
  border-radius: 50%;
  @apply opacity-100;
  background-color: #cdd5f7;
}
.steps-slider-parent .slick-dots li.slick-active button:before {
  background: #14337e;
  @apply opacity-100;
}
.slick-dots{
    bottom: -12px;
}
/* slick dots end */
